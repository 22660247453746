<div class="page-content">
  <div class="card-container">
    <mat-card appearance="outlined" class="card-item width65">
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon>badge</mat-icon>
        </div>
        <mat-card-title>Organization</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-divider></mat-divider>
        <div class="attribute-container">
          <div class="attribute-name-item">ID</div>
          <div class="attribute-value-item">{{ customer?.id }}</div>
        </div>
        <div class="attribute-container">
          <div class="attribute-name-item">Name</div>
          <div class="attribute-value-item">{{ customer?.name }}</div>
        </div>
        <div class="attribute-container">
          <div class="attribute-name-item">Subscription</div>
          <div class="attribute-value-item">{{ customer?.subscription }}</div>
        </div>
        <div class="attribute-container">
          <div class="attribute-name-item">Notification Languages</div>
          <div class="attribute-value-item">
            <mat-chip-set>
              @for (lang of getActiveNotificationLanguages(); track lang) {
                <mat-chip [matTooltip]="lang.name" class="active">{{ lang.code | uppercase }}</mat-chip>
              }
              @for (lang of getInactiveNotificationLanguages(); track lang) {
                <mat-chip [matTooltip]="lang.name + ' (inactive)'" class="inactive">{{ lang.code | uppercase }}
                </mat-chip>
              }
            </mat-chip-set>
          </div>
        </div>
        <div class="attribute-container">
          <div class="attribute-name-item">Computed Event Types</div>
          <div class="attribute-value-item">
            <span *ngIf="customer.computedEventTypes?.length === 0">-</span>
            <li *ngFor="let eventType of customer.computedEventTypes">{{ eventType }}</li>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card appearance="outlined" class="card-item width35">
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon>email</mat-icon>
        </div>
        <mat-card-title>User</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-divider></mat-divider>
        <div class="attribute-container">
          <div class="attribute-name-item">Username</div>
          <div class="attribute-value-item">{{ authTokenService.getUsername() }}</div>
        </div>
        <div class="attribute-container">
          <div class="attribute-name-item">Permissions</div>
          <div class="attribute-value-item">
            <li *ngFor="let permission of authTokenService.getPermissions()">{{ permission }}</li>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="card-container">
    <mat-card *ngIf="authTokenService.hasPermission(Permission.ADMIN_MOOST)" appearance="outlined"
              class="card-item width65">
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon>badge</mat-icon>
        </div>
        <mat-card-title>ID Token</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-divider></mat-divider>
        <div class="attribute-container">
          <textarea cdkTextareaAutosize
                    readonly>{{ JSON.stringify(authTokenService.getIdToken(), null, 4) }}</textarea>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card *ngIf="authTokenService.hasPermission(Permission.ADMIN_MOOST)" appearance="outlined"
              class="card-item width35">
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon>policy</mat-icon>
        </div>
        <mat-card-title>Access Token</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-divider></mat-divider>
        <div class="attribute-container">
          <textarea cdkTextareaAutosize
                    readonly>{{ JSON.stringify(authTokenService.getAccessToken(), null, 4) }}</textarea>
        </div>
      </mat-card-content>
    </mat-card>

  </div>
</div>
