import {EvaluationContext} from '../../monaco-rule-language-editor/validator/evaluation-context';
import {Kind, Token} from '../../monaco-rule-language-editor/validator/token';
import {RuleLanguageValidator} from '../../monaco-rule-language-editor/validator/rule-language-validator';
import {TermType} from '../../monaco-rule-language-editor/validator/term-type';
import {TermStructure} from '../../monaco-rule-language-editor/validator/term-structure';

enum TranslatableTextType {
  PlainText = 'PlainText',
  RuleLanguageTerm = 'RuleLanguageTerm'
}

export class TranslationHelper {
  private readonly PLACEHOLDER_NAME_PREFIX: string = '{{ param-';
  private readonly PLACEHOLDER_NAME_SUFFIX: string = ' }}';
  private readonly type: TranslatableTextType;
  private readonly placeholders: string[] = [];

  constructor(type: TranslatableTextType) {
    this.type = type;
  }

  static forPlainText(): TranslationHelper {
    return new TranslationHelper(TranslatableTextType.PlainText);
  }

  static forRuleLanguageTerm(): TranslationHelper {
    return new TranslationHelper(TranslatableTextType.RuleLanguageTerm);
  }

  toTranslatableText(text: string): string {
    switch (this.type) {
      case TranslatableTextType.RuleLanguageTerm:
        return this.toTranslatableRuleLanguageTerm(text);
      case TranslatableTextType.PlainText:
        return text;
      default:
        throw new Error("Unsupported type: " + this.type);
    }
  }

  private toTranslatableRuleLanguageTerm(text: string): string {
    if (this.placeholders.length > 0) {
      throw new Error("Re-usage of TranslationHelper detected. Use a new instance for every text block which has to be translated.");
    }
    const context: EvaluationContext = new EvaluationContext();
    const tokens: Token[] = new RuleLanguageValidator().evaluate(text, context, [TermType.TEXT], [TermStructure.SCALAR]);
    let bracketNestLevel: number = 0;
    let translatableText: string = '';
    let termStart: number = -1;
    let prevTermEnd: number = 0;
    for (let token of tokens) {
      if (token.getKind() === Kind.LITERAL && bracketNestLevel == 0 && TranslationHelper.isTextWithSurroundingQuotes(token.getContent())) {
        if (termStart >= 0) {
          const placeholderValue: string = text.substring(termStart, token.getStartPosition());
          this.placeholders.push(placeholderValue);
          translatableText += this.getPlaceholderText(this.placeholders.length);
          termStart = -1;
        }
        translatableText += TranslationHelper.getTextWithoutSurroundingQuotes(token.getContent());
      } else {
        if (termStart == -1) {
          termStart = prevTermEnd; // let's take end-position of previous term instead of start-position of current term so that we do not lose white spaces
        }
        if (token.getKind() === Kind.OPEN_BRACKET) {
          bracketNestLevel++;
        } else if (token.getKind() === Kind.CLOSE_BRACKET) {
          bracketNestLevel--;
        }
      }
      prevTermEnd = token.getEndPosition();
    }
    if (termStart >= 0) {
      const placeholderValue: string = text.substring(termStart);
      this.placeholders.push(placeholderValue);
      translatableText += this.getPlaceholderText(this.placeholders.length);
    }
    return translatableText;
  }

  fromTranslatedText(text: string): string {
    switch (this.type) {
      case TranslatableTextType.RuleLanguageTerm:
        return this.fromTranslatedTextToRuleLanguageTerm(text);
      case TranslatableTextType.PlainText:
        return text;
      default:
        throw new Error("Unsupported type: " + this.type);
    }
  }

  private fromTranslatedTextToRuleLanguageTerm(text: string): string {
    try {
      let fixedText: string = '';
      let fromPos: number = 0;
      let toPos: number = text.indexOf(this.PLACEHOLDER_NAME_PREFIX, fromPos);
      while (toPos >= 0) {
        if (fromPos < toPos) {
          fixedText += TranslationHelper.getTextWithSurroundingQuotes(text.substring(fromPos, toPos));
        }
        fromPos = toPos;
        toPos = text.indexOf(this.PLACEHOLDER_NAME_SUFFIX, fromPos)
        if (toPos < 0) {
          throw new Error("missing closing brackets of placeholder name ")
        }
        const placeholderIndex: number = Number(text.substring(fromPos + this.PLACEHOLDER_NAME_PREFIX.length, toPos)) - 1;
        if (placeholderIndex < 0 || placeholderIndex >= this.placeholders.length) {
          throw new Error("missing closing brackets of placeholder name ")
        }
        fixedText += this.placeholders[placeholderIndex];
        fromPos = toPos + this.PLACEHOLDER_NAME_SUFFIX.length;
        toPos = text.indexOf(this.PLACEHOLDER_NAME_PREFIX, fromPos);
      }
      if (fromPos < text.length) {
        fixedText += TranslationHelper.getTextWithSurroundingQuotes(text.substring(fromPos));
      }
      return fixedText;
    } catch (e) {
      console.error("Unexpected translated text: " + text, e);
      return text;
    }
  }

  private getPlaceholderText(index: number): string {
    return `${this.PLACEHOLDER_NAME_PREFIX}${index}${this.PLACEHOLDER_NAME_SUFFIX}`;
  }

  static getTextWithoutSurroundingQuotes(text: string): string {
    if (TranslationHelper.isTextWithSurroundingQuotes(text)) {
      return text.substring(1, text.length - 1);
    }
    return text;
  }

  static isTextWithSurroundingQuotes(text: string): boolean {
    return /^['"].*['"]$/.test(text);
  }

  static getTextWithSurroundingQuotes(text: string): string {
    if (text.indexOf('"') < 0) {
      return `"${text}"`;
    } else if (text.indexOf("'") < 0) {
      return `'${text}'`;
    } else {
      return `'${text.replaceAll("'", "‘")}'`;
    }
  }
}
