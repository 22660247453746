import {NgModule} from '@angular/core';
import {MoostUsersProfileComponent} from './moost-users-profile/moost-users-profile.component';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatDividerModule} from '@angular/material/divider';
import {CommonModule} from '@angular/common';
import {MatChip, MatChipSet} from '@angular/material/chips';
import {MatTooltip} from '@angular/material/tooltip';
import {CdkTextareaAutosize} from "@angular/cdk/text-field";
import {FormsModule} from "@angular/forms";
import {MatInput} from "@angular/material/input";

@NgModule({
  declarations: [
    MoostUsersProfileComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatCardModule,
    MatDividerModule,
    MatChipSet,
    MatChip,
    MatTooltip,
    CdkTextareaAutosize,
    FormsModule,
    MatInput,
  ]
})
export class UsersModule {
}
