import {Component, Input, OnInit} from '@angular/core';
import {IBuilding} from '../buildings.models';
import {BehaviorSubject, Observable} from 'rxjs';
import {EChartsOption} from 'echarts/types/dist/echarts';
import {CallbackDataParams} from 'echarts/types/dist/shared';
import {ColorPalette, toRgba} from '../../shared-module/color-palette';

@Component({
  selector: 'app-moost-profile-commercial-building-chart',
  templateUrl: './moost-profile-commercial-building-chart.component.html'
})
export class MoostProfileCommercialBuildingChartComponent implements OnInit {
  @Input()
  building: IBuilding;

  private optionsSubject: BehaviorSubject<EChartsOption> = new BehaviorSubject<EChartsOption>({});
  options: Observable<EChartsOption> = this.optionsSubject.asObservable();

  ngOnInit(): void {
    this.updateChart();
  }

  private updateChart(): void {
    this.optionsSubject.next({
      tooltip: {
        formatter: function (params: CallbackDataParams): string {
          return params.value ? Number(params.value).toFixed(2) : '';
        }
      },
      series: [
        {
          type: 'gauge',
          startAngle: 160,
          endAngle: 20,
          center: ['50%', '75%'],
          radius: '80%',
          min: 0,
          max: 1,
          splitNumber: 20,
          axisLine: {
            lineStyle: {
              width: 20,
              color: [
                [0.25, toRgba(ColorPalette.OPPOSITE_PRIMARY, 1.0)],
                [0.50, toRgba(ColorPalette.OPPOSITE_PRIMARY, 0.5)],
                [0.75, toRgba(ColorPalette.PRIMARY, 0.5)],
                [1.00, toRgba(ColorPalette.PRIMARY, 1.0)]
              ]
            }
          },
          pointer: {
            show: this.building?.profile?.commercialBuildingScore >= 0,
            icon: 'pin',
            length: 25,
            width: 20,
            offsetCenter: [0, '-95%'],
            itemStyle: {
              color: 'auto'
            }
          },
          axisTick: {
            length: 2,
            distance: -25,
            lineStyle: {
              color: 'auto',
              width: 1
            }
          },
          splitLine: {
            length: 4,
            distance: -25,
            lineStyle: {
              color: 'auto',
              width: 1
            }
          },
          axisLabel: {
            color: '#464646',
            distance: 65,
            rotate: 'tangential',
            formatter: function (value: number): string {
              if (value === 0.85) {
                return 'Commercial';
              } else if (value === 0.15) {
                return 'Residential';
              }
              return '';
            }
          },
          detail: {
            fontSize: 20,
            offsetCenter: [0, '-35%'],
            valueAnimation: true,
            formatter: function (value): any {
              if (value < 0.33) {
                return 'Residential';
              } else if (value >= 0.33) {
                return 'Commercial';
              } else { // if NaN
                return '';
              }

            }
          },
          data: [
            {
              value: this.building?.profile?.commercialBuildingScore
            }
          ]
        }
      ]
    });
  }
}
