<form (ngSubmit)="onSubmit()" [formGroup]="this.datasetForm">
  <div mat-dialog-title>
    <div class="header-container">
      <h1 class="title">
        Dataset
      </h1>
      <button class="btn-close" mat-dialog-close mat-icon-button matTooltip="Cancel">
        <mat-icon class="gray-icon">close</mat-icon>
      </button>
    </div>
  </div>

  <div mat-dialog-content>
    <div class="form-container">
      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input [value]="dataset.name" cdkFocusInitial formControlName="name" matInput placeholder="Name">
        <mat-error *ngIf="(datasetForm.controls.name.touched || datasetForm.controls.name.dirty) &&
        datasetForm.controls.name.invalid && datasetForm.controls.name.errors?.['required']">
          A name is required
        </mat-error>
        <mat-error *ngIf="(datasetForm.controls.name.touched || datasetForm.controls.name.dirty) &&
        datasetForm.controls.name.invalid && datasetForm.controls.name.errors?.['notUnique']">
          The name of a dataset has to be unique per rule.
        </mat-error>
        <mat-error *ngIf="(datasetForm.controls.name.touched || datasetForm.controls.name.dirty) &&
        datasetForm.controls.name.invalid && datasetForm.controls.name.errors.pattern">
          Only letters, numbers and underscores (_) are allowed in a dataset name
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Description</mat-label>
        <input [value]="dataset.description" formControlName="description" matInput placeholder="Description">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Type</mat-label>
        <mat-select formControlName="type" required>
          <!--https://stackoverflow.com/questions/38554562/how-can-i-use-ngfor-to-iterate-over-typescript-enum-as-an-array-of-strings-->
          <mat-option *ngFor="let datasetType of datasetTypes | enum" [value]="datasetType[1]">
            {{ datasetType[0] }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="(datasetForm.controls.type.touched || datasetForm.controls.type.dirty) &&
        datasetForm.controls.type.invalid && datasetForm.controls.type.errors?.['required']">
          Please choose a valid type for the dataset
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Event Types</mat-label>
        <mat-select formControlName="eventTypes" multiple required>
          <mat-option *ngFor="let eventType of eventTypes;" [value]="eventType">
            {{ eventType }}
          </mat-option>
        </mat-select>
        <mat-spinner *ngIf="isLoadingEventTypes" [diameter]="24" matPrefix style="margin: 0 16px;"></mat-spinner>
        <mat-error *ngIf="(datasetForm.controls.eventTypes.touched || datasetForm.controls.eventTypes.dirty) &&
          datasetForm.controls.eventTypes.invalid">
          Please choose at least one event type
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Source Types</mat-label>
        <mat-select formControlName="sourceTypes" multiple>
          <mat-option *ngFor="let sourceType of sourceTypes" [value]="sourceType">
            {{ sourceType }}
          </mat-option>
        </mat-select>
        <mat-spinner *ngIf="isLoadingSourceTypes" [diameter]="24" matPrefix style="margin: 0 16px;"></mat-spinner>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Validity Time Frame</mat-label>
        <input [value]="secondsToDurationString(dataset.timeframe)" formControlName="timeframe" matInput
               placeholder="(Valid values are e.g. 1d, 15h, 1min or 30s.)">
        <mat-error *ngIf="(datasetForm.controls.timeframe.touched || datasetForm.controls.timeframe.dirty) &&
        datasetForm.controls.timeframe.invalid && datasetForm.controls.timeframe.errors?.pattern">
          The time frame value has an invalid format. (Valid values are e.g. 1d, 15h, 1min, 30s etc.)
        </mat-error>
        <mat-error *ngIf="(datasetForm.controls.timeframe.touched || datasetForm.controls.timeframe.dirty) &&
        datasetForm.controls.timeframe.invalid && datasetForm.controls.timeframe.errors?.maxReached">
          The allowed max time frame is {{ getMaxDaysTimeframe() }} days. Please use a shorter
          time frame.
        </mat-error>
        <mat-hint>
          When evaluating the condition, checks that events are within the time frame.
        </mat-hint>
      </mat-form-field>
    </div>
  </div>

  <div align="end" mat-dialog-actions>
    <button [disabled]="!datasetForm.valid" class="btn-save" color="primary" mat-dialog-close mat-flat-button
            type="submit">
      APPLY
    </button>
  </div>
</form>
