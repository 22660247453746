<div class="building-selector">
  <section class="filter-selection">
    <mat-form-field appearance="outline">
      <mat-label>Filter for Building Selector</mat-label>
      <mat-select (selectionChange)="applyBuildingFilter($event)" [value]="buildingFilter" multiple>
        <mat-option [value]="FILTER_DATASET_SCOPED_BUILDINGS">Dataset scoped buildings</mat-option>
        <mat-option [value]="FILTER_FAVORITE_BUILDINGS">Favorite buildings</mat-option>
        <mat-option [value]="FILTER_EARLY_ADOPTER_BUILDINGS">Early adopter buildings</mat-option>
      </mat-select>
    </mat-form-field>
  </section>

  <mat-form-field appearance="outline">
    <mat-label>Building Selector</mat-label>
    <div *ngIf="isLoadingBuildings" class="select-spinner">
      <mat-spinner [diameter]="24"></mat-spinner>
    </div>
    <mat-select (selectionChange)="setSelectedCustomerBuildingId($event.value)" [(value)]="selectedCustomerBuildingId"
                matTooltip="Show data of selected building.
              Previous: CTRL + j
              Next: CTRL + k"
                matTooltipClass="multiline">
      <mat-select-trigger class="option-container">
        <mat-icon (click)="$event.stopPropagation(); toggleFavoriteBuilding(selectedCustomerBuildingId)"
                  [class]="isFavoriteBuilding(selectedCustomerBuildingId) ? 'material-icons-outlined' : ''">
          star
        </mat-icon>
        <div class="div-early-adopter">
          @if (isEarlyAdopterBuilding(selectedCustomerBuildingId)) {
            <mat-icon matTooltip="Early Adopter">school</mat-icon>
          }
        </div>
        {{ selectedCustomerBuildingId }}
      </mat-select-trigger>
      <mat-option *ngFor="let buildingId of currentSelectableBuildingIdsSlice" [value]="buildingId">
        <div class="option-container">
          <mat-icon (click)="$event.stopPropagation(); toggleFavoriteBuilding(buildingId)"
                    [class]="isFavoriteBuilding(buildingId) ? 'material-icons-outlined' : ''"
          >
            star
          </mat-icon>
          <div class="div-early-adopter">
            @if (isEarlyAdopterBuilding(buildingId)) {
              <mat-icon matTooltip="Early Adopter">school</mat-icon>
            }
          </div>
          {{ buildingId }}
        </div>
      </mat-option>
      @if (currentSelectableBuildingIdsSlice.length < totalSelectableCustomerBuildingIs) {
        <p class="btn-show-more" (click)="$event.stopPropagation(); showMoreSelectableBuildings()">
          Show more buildings
        </p>
      }
    </mat-select>
  </mat-form-field>
</div>
