import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
  Dataset,
  DatasetDialogData,
  DatasetType,
  IDataset,
  IDatasetDialogResult,
  secondsToDurationString,
  stringDurationToSeconds
} from '../rules.models';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {EventsService} from '../../events-module/events.service';
import {EMPTY} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Component({
  selector: 'app-moost-dataset-detail',
  templateUrl: './moost-dataset-detail.component.html',
  styleUrls: ['./moost-dataset-detail.component.scss']
})
export class MoostDatasetDetailComponent implements OnInit {
  public static readonly STRING_TIME_DURATION_REGEXP: string = "^(0|(\\d+)(d|h|min|s))$"
  public static readonly VALID_NAME_REGEXP: string = "^[a-zA-Z0-9_]*$"
  public static readonly MAX_DAYS_TIMEFRAME: number = 42
  readonly datasetTypes = DatasetType;

  from: number;
  to: number;
  dataset: IDataset = new Dataset();
  datasetForm: FormGroup;
  datasetKey: string;
  sourceTypes: string[];
  eventTypes: string[];
  forbiddenDatasetNames: string[] = [];
  isLoadingEventTypes: boolean = true;
  isLoadingSourceTypes: boolean = true;
  protected readonly secondsToDurationString = secondsToDurationString;

  constructor(private dialogRef: MatDialogRef<MoostDatasetDetailComponent, IDatasetDialogResult>,
              private eventsService: EventsService, @Inject(MAT_DIALOG_DATA) datasetDialogData: DatasetDialogData,
              private formBuilder: FormBuilder) {
    this.dataset = datasetDialogData.dataset;
    this.datasetKey = datasetDialogData.dataset.name;
    this.from = datasetDialogData.from;
    this.to = datasetDialogData.to;
    this.forbiddenDatasetNames = datasetDialogData.forbiddenDatasetNames;
    this.datasetForm = this.buildDatasetForm();
  }

  ngOnInit(): void {
    this.loadEventTypes();
    this.loadSourceTypes();

    this.datasetForm.get("type").valueChanges.subscribe((selectedDatasetType: DatasetType) => {
      const timeframeFormControl: AbstractControl<string, string> = this.datasetForm.get<string>("timeframe");
      timeframeFormControl.setValidators([
        Validators.required,
        Validators.pattern(MoostDatasetDetailComponent.STRING_TIME_DURATION_REGEXP),
        this.isTimeframeBelowMax()
      ]);
      timeframeFormControl.updateValueAndValidity();
    });
  }

  onSubmit(): void {
    const dataset: IDataset = {
      name: this.datasetForm.get("name").value,
      type: this.datasetForm.get("type").value,
      description: this.datasetForm.get("description").value,
      event_types: this.datasetForm.get("eventTypes").value,
      source_types: this.datasetForm.get("sourceTypes").value,
      timeframe: stringDurationToSeconds(this.datasetForm.get("timeframe").value),
    }

    this.dialogRef.close({
      datasetKey: (this.datasetKey ? this.datasetKey : dataset.name),
      dataset: dataset
    });
  }

  isDatasetNameUniqueValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isUnique: boolean = this.forbiddenDatasetNames.indexOf(control.value) == -1
      return (isUnique ? null : {notUnique: {value: control.value}});
    };
  }

  isTimeframeBelowMax(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isTimeframeBelowMax: boolean = (
        stringDurationToSeconds(control.value) <= MoostDatasetDetailComponent.MAX_DAYS_TIMEFRAME * 86400
      );
      return (isTimeframeBelowMax ? null : {maxReached: {value: control.value}});
    };
  }

  getMaxDaysTimeframe(): number {
    return MoostDatasetDetailComponent.MAX_DAYS_TIMEFRAME;
  }

  private loadEventTypes(): void {
    this.datasetForm.get("eventTypes").disable();
    this.isLoadingEventTypes = true;

    this.eventsService.getEventTypes().pipe(
      catchError(() => EMPTY)
    ).subscribe((eventTypes: string[]): void => {
      this.eventTypes = eventTypes;
      this.datasetForm.get("eventTypes").enable();
      this.isLoadingEventTypes = false;
    });
  }

  private loadSourceTypes(): void {
    this.datasetForm.get("sourceTypes").disable();
    this.isLoadingSourceTypes = true;

    this.eventsService.getSourceTypes().pipe(
      catchError(() => EMPTY)
    ).subscribe((sourceTypes: string[]): void => {
      this.sourceTypes = sourceTypes;
      this.datasetForm.get("sourceTypes").enable();
      this.isLoadingSourceTypes = false;
    });
  }

  private buildDatasetForm(): FormGroup {
    return this.formBuilder.group({
      name: new FormControl<string>(this.dataset.name, [
        Validators.required,
        Validators.pattern(MoostDatasetDetailComponent.VALID_NAME_REGEXP),
        this.isDatasetNameUniqueValidator()
      ]),
      description: new FormControl<string>(this.dataset.description),
      type: new FormControl<DatasetType>(this.dataset.type, Validators.required),
      eventTypes: new FormControl<string[]>(this.dataset.event_types, Validators.required),
      sourceTypes: new FormControl<string[]>(this.dataset.source_types),
      timeframe: new FormControl<string>(this.secondsToDurationString(this.dataset.timeframe), [
        Validators.pattern(MoostDatasetDetailComponent.STRING_TIME_DURATION_REGEXP),
        this.isTimeframeBelowMax()
      ])
    });
  }
}
