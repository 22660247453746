import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {RulesService} from '../rules.service';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {
  Dataset,
  IDataset,
  INotification,
  IRule,
  NotificationTexts,
  Rule,
  RuleIcon,
  RuleVersion,
  secondsToDurationString,
  stringDurationToSeconds
} from '../rules.models';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {DatasetsService} from '../datasets.service';
import {IPushNotification} from '../../notifications-module/notifications.models';
import {Subscription} from 'rxjs';
import {TermType} from '../monaco-rule-language-editor/validator/term-type';
import {MoostHeaderService} from '../../moost-header/moost-header.service';
import {Permission} from '../../auth-token-module/auth-token.models';
import {AuthTokenService} from '../../auth-token-module/auth-token.service';
import {TermStructure} from '../monaco-rule-language-editor/validator/term-structure';
import {
  ConfirmDialogModel,
  MoostConfirmDialogComponent
} from '../../shared-module/moost-confirm-dialog/moost-confirm-dialog.component';
import {Clipboard} from '@angular/cdk/clipboard';
import {MoostRuleImportDialogComponent} from '../moost-rule-import-dialog/moost-rule-import-dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import cron from 'cron-validate';
import cronstrue from 'cronstrue';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MoostDatasetDetailComponent} from '../moost-dataset-detail/moost-dataset-detail.component';
import * as moment from 'moment';
import {
  RuleLanguageFormFieldComponent
} from '../monaco-rule-language-editor/rule-language-form-field/rule-language-form-field.component';
import {
  MoostSendTestNotificationComponent
} from '../moost-send-test-notification-dialog/moost-send-test-notification.component';
import {RelativeDuration} from './relative-duration';
import {RuleComparator} from './rule-comparator';
import {CustomerService} from '../../shared-module/customer.service';
import {Customer} from '../../shared-module/customer.model';
import {LanguageService} from '../../shared-module/language.service';

@Component({
  selector: 'app-moost-rules-configuration',
  templateUrl: './moost-rules-configuration.component.html',
  styleUrls: ['./moost-rules-configuration.component.scss'],
  providers: [DatasetsService],
  animations: [
    trigger('toggleFormVisibility', [
      state('open', style({height: '*', opacity: 1})),
      state('closed', style({height: '0', opacity: 0})),
      transition('open <=> closed', [animate('0.2s')]),
    ])
  ]
})
export class MoostRulesConfigurationComponent implements OnInit, OnDestroy {
  protected readonly Rule = Rule;
  protected readonly RuleComparator = RuleComparator;
  protected readonly secondsToDurationString = secondsToDurationString;

  rule: Rule;
  notificationLanguages: string[] = [];
  errorBadgeLangTab: Set<string> = new Set();
  actionSelection = [
    {id: 'OPENAPP', text: 'Open App', isVisibleParameter: true},
    {id: 'OPENWEB', text: 'Open Web', isVisibleParameter: true},
    {id: 'STOPDELIVERY', text: 'Do not notify again', isVisibleParameter: false},
    {id: 'DISMISS', text: 'Dismiss', isVisibleParameter: false},
  ];
  ruleForm: FormGroup;
  //Date Range selector component
  maxDate: Date = moment().endOf('day').add(7, "days").toDate();
  startTimestampMillis: number = moment().startOf('day').subtract({days: 14}).toDate().getTime();
  selectedDateRange: FormGroup = new FormGroup({
    startDate: new FormControl(new Date(this.startTimestampMillis), [Validators.required]),
    endDate: new FormControl(new Date(this.endTimestampMillis), [Validators.required]),
  });
  isLoadingRule: boolean;
  isSimulationRunning: boolean;
  simulatedNotifications: IPushNotification[];
  createOrUpdateError: string;
  errorCode: string;
  selectedCustomerBuildingId: string;
  datasets: Dataset[];
  protected readonly Permission = Permission;
  protected readonly TermType = TermType;
  protected readonly TermStructure = TermStructure;
  protected readonly cronstrue = cronstrue;
  protected readonly RegExp = RegExp;
  protected readonly RuleIcon = RuleIcon;
  private ruleSubscription: Subscription;
  private routeSubscription: Subscription;
  private simulationSubscription: Subscription;
  private datasetsSubscription: Subscription;
  private allRulesSubscription: Subscription;
  private ruleVersionsSubscription: Subscription;
  private libraryRuleSubscription: Subscription;
  private dialogSubscription: Subscription;
  private forbiddenRuleNames: string[];
  ruleVersions: RuleVersion[];
  originalRule: Rule;
  comparingRule: Rule;
  libraryRule: Rule;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private headerService: MoostHeaderService,
              protected datasetsService: DatasetsService,
              private rulesService: RulesService,
              private customerService: CustomerService,
              protected languageService: LanguageService,
              private formBuilder: FormBuilder,
              public dialog: MatDialog,
              protected authTokenService: AuthTokenService,
              private clipboard: Clipboard,
              private snackBar: MatSnackBar) {
    this.headerService.setHeader('Rule Configuration', '/rules');
  }

  _endTimestampMillis: number = moment().toDate().getTime();

  get endTimestampMillis(): number {
    return this._endTimestampMillis;
  }

  set endTimestampMillis(endTimestampMillis: number) {
    if (endTimestampMillis > this.maxDate.getTime()) {
      this._endTimestampMillis = this.maxDate.getTime();
    } else {
      this._endTimestampMillis = endTimestampMillis
    }
  }

  ngOnInit(): void {
    this.createOrUpdateError = null;
    this.datasetsSubscription = this.datasetsService.datasetsSource.subscribe((datasets: Dataset[]): void => {
      this.datasets = datasets;
    });
    this.customerService.getCustomer().subscribe((customer: Customer): void => {
      this.notificationLanguages = customer.notificationLanguages;
      this.routeSubscription = this.route.params.subscribe({
        next: (params: Params): void => {
          const ruleId: string = params['id'];
          if (ruleId) {
            this.loadRule(ruleId);
            this.loadRuleVersions(ruleId);
            // make sure that potential 'libraryRule' query parameter is reset for existing rules
            this.router.navigate([], {queryParams: {'libraryRule': null}, queryParamsHandling: "merge"});
          } else {
            this.ruleForm = this.buildRuleForm(null);
            this.prefillFormWithDefaultValues();
          }
          this.loadForbiddenRuleNames(ruleId);
          // handle query parameters only after path parameters were considered
          this.route.queryParams.subscribe((queryParams: Params) => {
            this.readRouteQueryParameters(queryParams);
            if (!ruleId) { // only consider query parameter 'libraryRule' if no path parameter 'id' is present
              const libraryRuleId: any = queryParams['libraryRule'];
              if (libraryRuleId) {
                this.libraryRuleSubscription = this.rulesService.getLibraryRule(libraryRuleId)
                  .subscribe((rule: Rule): void => {
                    rule.templateRuleId = libraryRuleId;
                    this.importRule(rule);
                  });
              }
            }
          });
        },
        error: (error): void => {
          console.error(error);
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.simulationSubscription?.unsubscribe()
    this.ruleSubscription?.unsubscribe();
    this.routeSubscription?.unsubscribe();
    this.datasetsSubscription?.unsubscribe();
    this.allRulesSubscription?.unsubscribe();
    this.ruleVersionsSubscription?.unsubscribe();
    this.libraryRuleSubscription?.unsubscribe();
    this.dialogSubscription?.unsubscribe();
  }

  @HostListener('window:keyup.control.enter', ['$event'])
  runSimulation(): void {
    if (!this.isSimulationRunning) {
      this.isSimulationRunning = true;
      const startTimeRangeMillis: number = this.selectedDateRange.controls.startDate.value.valueOf();
      const endTimeRangeMillis: number = this.selectedDateRange.controls.endDate.value.valueOf();
      const maxTimeFrameSeconds: number = this.datasetsService.getDatasetMaxTimeframe(this.datasets);
      const ruledId: string = this.rule ? this.rule.id : null;
      const updatedRule: IRule = this.buildRule(ruledId, this.ruleForm);

      this.simulationSubscription?.unsubscribe();
      this.simulationSubscription = this.rulesService.runSimulation(updatedRule, this.selectedCustomerBuildingId,
        Math.round(startTimeRangeMillis / 1000) - maxTimeFrameSeconds,
        Math.round(endTimeRangeMillis / 1000)
      ).subscribe({
        next: (simulatedNotifications: IPushNotification[]): void => {
          this.simulatedNotifications = simulatedNotifications;
        },
        error: (error): void => {
          this.createOrUpdateError = "The simulation ended with an internal error. Try again or contact support.";
          console.error(error);
          this.isSimulationRunning = false;
        },
        complete: (): void => {
          this.createOrUpdateError = "";
          this.isSimulationRunning = false;
        }
      });
    }
  }

  public isTimeBasedChanged(event: MatSlideToggleChange): void {
    const conditionControl: AbstractControl = this.ruleForm.get('condition');
    const timeBasedCronControl: AbstractControl = this.ruleForm.get('timeBasedCron');
    const isTimeBased: boolean = event.checked;
    conditionControl.setValidators(this.getConditionControlValidators(isTimeBased));
    timeBasedCronControl.setValidators(this.getTimeBasedCronControlValidators(isTimeBased));
    conditionControl.updateValueAndValidity();
    timeBasedCronControl.updateValueAndValidity();
  }

  isStreakChanged(event: MatSlideToggleChange): void {
    const streakConditionControl: AbstractControl = this.ruleForm.get('streakCondition');
    const isStreak: boolean = event.checked;
    streakConditionControl.setValidators(this.getStreakConditionControlValidators(isStreak));
    streakConditionControl.updateValueAndValidity();
  }

  dateChanged(event: MatDatepickerInputEvent<Date>): void {
    if (event.value != null && this.selectedDateRange.status === "VALID") {
      this.startTimestampMillis = this.selectedDateRange.controls.startDate.value.valueOf();
      this.endTimestampMillis = moment(this.selectedDateRange.controls.endDate.value.valueOf()).endOf('day').toDate().getTime();

      this.writeRouteQueryParameters();
    }
  }

  onSubmit(): void {
    this.createOrUpdateError = null;
    const ruledId: string = this.rule ? this.rule.id : null;
    const updatedRule: IRule = this.buildRule(ruledId, this.ruleForm);

    if (ruledId) {
      this.rulesService.updateRule(updatedRule).subscribe({
        next: (): void => {
          this.loadRule(this.rule.id);
          this.loadRuleVersions(this.rule.id);
        },
        error: (error): void => {
          this.createOrUpdateError = `Update failed: ${error.message}`;
        }
      });
    } else {
      this.rulesService.addRule(updatedRule).subscribe({
          next: (rule: IRule): void => {
            if (rule?.id) {
              void this.router.navigate([`/rules/${rule.id}`], {queryParamsHandling: 'merge'});
            } else {
              this.createOrUpdateError = `Add rule failed. Try again or contact support.`;
            }
          },
          error: (error): void => {
            this.createOrUpdateError = `Add rule failed: ${error.message}`;
          }
        }
      );
    }
  }

  refreshView(): void {
    // workaround to solve rendering issue of monaco editor component, when another tab is
    // selected, therefore trigger a resize event to make browser render content of
    // rule-language-form-field.
    // See e.g.: https://github.com/angular/components/issues/20340
    window.dispatchEvent(new Event('resize'));
  }

  isActionParameterVisible(qualifierId: string): boolean {
    const ruleControls = this.ruleForm['controls'];
    const notificationControls = ruleControls.notification['controls'];
    const actionQualifier: string = notificationControls[`actionQualifier_${qualifierId}`].value;
    return this.actionSelection.find(it => it.id === actionQualifier)?.isVisibleParameter;
  }

  deleteRule(): void {
    const dialogConfig: MatDialogConfig<ConfirmDialogModel> = new MatDialogConfig();
    dialogConfig.width = '500px';
    dialogConfig.data = {
      title: "Delete Rule",
      message: `Do you really want to delete the rule with name '${this.rule.name}'?`,
      confirm: "YES",
      dismiss: "NO",
      icon: "warning_amber",
      confirmColor: "warn"
    };

    this.dialog.open(MoostConfirmDialogComponent, dialogConfig).afterClosed()
      .subscribe((confirmed: boolean): void => {
        if (confirmed) {
          this.rulesService.deleteRule(this.rule.id).subscribe({
            next: (): void => {
              this.router.navigate(["rules"], {queryParamsHandling: 'merge'});
            },
            error: (error): void => {
              this.createOrUpdateError = error.message;
              console.error(error.message);
            }
          })
        }
      });
  }

  exportRule(): void {
    const exportRule: IRule = this.buildRule(this.rule?.id, this.ruleForm);
    const success: boolean = this.clipboard.copy(JSON.stringify(exportRule));
    const message: string = success ? "Rule exported to Clipboard" : "Failed to export to Clipboard";
    this.snackBar.open(message, null, {
      duration: 1000,
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }

  openImportRuleDialog(): void {
    this.dialogSubscription = this.dialog.open(MoostRuleImportDialogComponent).afterClosed()
      .subscribe((importRule: IRule): void => {
        if (importRule != null) {
          this.importRule(importRule);
          this.snackBar.open("Rule imported from Clipboard. It is deactivated for now.", null, {
            duration: 2000,
            horizontalPosition: "center",
            verticalPosition: "top",
          })
        }
      });
  }

  importRule(importRule: IRule): void {
    importRule.id = this.rule?.id;
    if (importRule.templateRuleId) {
      if (this.authTokenService.isRuleLibCustomer()) {
        importRule.templateRuleId = null; // a LibraryRule should never refer to another rule
      } else { // check if the referenced library rule exists. If not then reset
        this.rulesService.getAllLibraryRules().subscribe({
          next: (libRules: Rule[]): void => {
            const found: IRule = libRules.find(libRule => libRule.id === importRule.templateRuleId);
            if (!found) {
              importRule.templateRuleId = null;
            }
          },
          error: (error): void => {
            console.error(error);
          }
        }).unsubscribe();
      }
    }
    this.setRuleRelatedFields(importRule);
    this.ruleForm.markAsDirty();
  }

  openSendTestNotificationDialog(): void {
    this.dialog.open(MoostSendTestNotificationComponent,
      {
        panelClass: 'send-test-notification-dialog',
        data: {
          customerBuildingId: this.selectedCustomerBuildingId,
          ruleId: this.rule.id,
          notificationJsonAsString: JSON.stringify(this.rule.notification, undefined, 4)
        }
      })
      .afterClosed().subscribe((sent: boolean): void => {
      if (sent) {
        this._endTimestampMillis = moment().toDate().getTime(); // trigger reloading notifications in rules-data-graph
      }
    });
  }

  private readRouteQueryParameters(queryParams: Params): void {
    const paramStartTimestamp: number = queryParams['s'];
    if (paramStartTimestamp) {
      this.startTimestampMillis = Number(paramStartTimestamp);
    }
    const paramEndTimestamp: number = queryParams['e'];
    if (paramEndTimestamp) {
      this.endTimestampMillis = Number(paramEndTimestamp);
    }
    this.selectedDateRange = new FormGroup({
      startDate: new FormControl(new Date(this.startTimestampMillis), [Validators.required]),
      endDate: new FormControl(new Date(this.endTimestampMillis), [Validators.required]),
    });
  }

  private writeRouteQueryParameters(): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {s: this.startTimestampMillis, e: this.endTimestampMillis},
      queryParamsHandling: 'merge'
    });
  }

  private loadRule(ruleId: string): void {
    this.isLoadingRule = true;
    this.ruleSubscription = this.rulesService.getRuleById(ruleId).subscribe({
        next: (rule: Rule): void => {
          this.setRuleRelatedFields(rule);
          this.isLoadingRule = false;
          this.errorCode = '';
          if (rule.templateRuleId) {
            this.libraryRuleSubscription = this.rulesService.getLibraryRule(rule.templateRuleId)
              .subscribe((rule: Rule): void => {
                this.libraryRule = rule;
              });
          }
        },
        error: (error): void => {
          console.error(error);
          if (error.status === 404) {
            this.errorCode = "NOT_FOUND";
          } else {
            this.errorCode = "ERROR";
          }
          this.isLoadingRule = false;
        }
      }
    )
  }

  private setRuleRelatedFields(rule: Rule): void {
    this.rule = rule;
    this.datasetsService.datasetsSource.next(this.rule.datasets);
    this.ruleForm = this.buildRuleForm(rule);
    this.updateErrorBadgeOnLanguageTabs();
  }

  private loadForbiddenRuleNames(selectedRuleId: string): void {
    this.allRulesSubscription = this.rulesService.getAllRules().subscribe((rules: IRule[]): void => {
      this.forbiddenRuleNames = rules
        .filter((rule: IRule) => rule.id !== selectedRuleId)
        .map((rule: IRule) => rule.name);
    });
  }

  private loadRuleVersions(selectedRuleId: string): void {
    this.ruleVersionsSubscription = this.rulesService.getRuleVersionsByRuleId(selectedRuleId).subscribe((rules: RuleVersion[]): void => {
      this.ruleVersions = rules.reverse();
    });
  }

  private getConditionControlValidators(isOptional: boolean): ValidatorFn[] {
    if (isOptional) {
      return [this.ruleLanguageTermValidator()];
    } else {
      return [Validators.required, this.ruleLanguageTermValidator()];
    }
  }

  private getTimeBasedCronControlValidators(isTimeBased: boolean): ValidatorFn[] {
    if (isTimeBased) {
      return [Validators.required, this.cronValidator()];
    } else {
      return [];
    }
  }

  private getStreakConditionControlValidators(isStreak: boolean): ValidatorFn[] {
    return isStreak ? [Validators.required, this.ruleLanguageTermValidator()] : null;
  }

  private buildRuleForm(rule: IRule): FormGroup {
    const notificationControl = {};
    notificationControl['actionQualifier_primary'] = new FormControl<string>(rule?.notification?.actionQualifier?.primary);
    notificationControl['actionQualifier_secondary'] = new FormControl<string>(rule?.notification?.actionQualifier?.secondary);
    for (let lang of this.notificationLanguages) {
      const texts: NotificationTexts = rule?.notification?.texts[lang];
      notificationControl[`title_${lang}`] = new FormControl<string>(texts?.title, Validators.required);
      notificationControl[`message_${lang}`] = new FormControl<string>(texts?.message, [Validators.required, this.ruleLanguageTermValidator()]);
      notificationControl[`actions_primary_text_${lang}`] = new FormControl<string>(texts?.actions?.primary?.text, Validators.required);
      notificationControl[`actions_primary_param_${lang}`] = new FormControl<string>(texts?.actions?.primary?.parameter);
      notificationControl[`actions_secondary_text_${lang}`] = new FormControl<string>(texts?.actions?.secondary?.text, Validators.required);
      notificationControl[`actions_secondary_param_${lang}`] = new FormControl<string>(texts?.actions?.secondary?.parameter);
    }
    notificationControl['command'] = new FormControl<string>(rule?.notification?.command, [this.ruleLanguageTermValidator()]);
    const ruleForm: FormGroup = this.formBuilder.group({
      name: new FormControl<string>(rule?.name, [Validators.required, this.ruleNameValidator()]),
      description: new FormControl<string>(rule?.description),
      topicIcon: new FormControl<string>(rule?.topicIcon),
      impactIcon: new FormControl<string>(rule?.impactIcon),
      isActiveState: new FormControl<boolean>(rule?.ruleState === Rule.ACTIVE),
      datasets: new FormControl<IDataset[]>(rule?.datasets),
      isRestrictedToEarlyAdopters: new FormControl<boolean>(rule?.isRestrictedToEarlyAdopters),
      resetStateWhenMatched: new FormControl<boolean>(rule?.resetStateWhenMatched),
      isTimeBased: new FormControl<boolean>(rule?.isTimeBased),
      timeBasedCron: new FormControl<string>(rule?.timeBasedCron, this.getTimeBasedCronControlValidators(rule?.isTimeBased)),
      condition: new FormControl<string>(rule?.condition, this.getConditionControlValidators(rule?.isTimeBased)),
      isStreak: new FormControl<boolean>(!!rule?.streakCondition),
      streakCondition: new FormControl<string>(rule?.streakCondition, this.getStreakConditionControlValidators(!!rule?.streakCondition)),
      match_threshold: new FormControl<string>(rule?.match_threshold, Validators.pattern(/^\d*$/)),
      time_between_triggers_seconds: new FormControl<string>(secondsToDurationString(rule?.time_between_triggers_seconds), [Validators.pattern(MoostDatasetDetailComponent.STRING_TIME_DURATION_REGEXP), Validators.required]),
      templateRuleId: new FormControl<string>(rule?.templateRuleId),
      notification: this.formBuilder.group(notificationControl),
    });
    ruleForm.markAllAsTouched();
    ruleForm.valueChanges.subscribe((e: any) => {
      this.updateErrorBadgeOnLanguageTabs();
    });
    return ruleForm;
  }


  private buildRule(ruleId: string, ruleForm: FormGroup): IRule {
    const ruleControls = ruleForm['controls'];
    const notificationControls = ruleControls.notification['controls'];
    const notification: INotification = {
      actionQualifier: {
        primary: notificationControls.actionQualifier_primary.value,
        secondary: notificationControls.actionQualifier_secondary.value,
      },
      texts: {},
      command: notificationControls.command.value,
    };
    for (let lang of this.notificationLanguages) {
      notification.texts[lang] = {
        title: notificationControls[`title_${lang}`].value,
        message: notificationControls[`message_${lang}`].value,
        actions: {
          primary: {
            text: notificationControls[`actions_primary_text_${lang}`].value,
            parameter: notificationControls[`actions_primary_param_${lang}`].value
          },
          secondary: {
            text: notificationControls[`actions_secondary_text_${lang}`].value,
            parameter: notificationControls[`actions_secondary_param_${lang}`].value,
          }
        }
      };
    }
    return {
      id: ruleId,
      customerId: this.authTokenService.getCustomerId(),
      name: ruleControls.name.value,
      description: ruleControls.description.value,
      topicIcon: ruleControls.topicIcon.value,
      impactIcon: ruleControls.impactIcon.value,
      ruleState: ruleControls.isActiveState.value ? Rule.ACTIVE : Rule.PAUSE,
      condition: ruleControls.condition.value,
      streakCondition: ruleControls.isStreak.value ? ruleControls.streakCondition.value : "",
      isRestrictedToEarlyAdopters: ruleControls.isRestrictedToEarlyAdopters.value,
      isTimeBased: ruleControls.isTimeBased.value,
      timeBasedCron: ruleControls.isTimeBased.value ? ruleControls.timeBasedCron.value : null,
      datasets: ruleControls.datasets.value,
      match_threshold: ruleControls.match_threshold.value,
      resetStateWhenMatched: ruleControls.resetStateWhenMatched.value,
      time_between_triggers_seconds: stringDurationToSeconds(ruleControls.time_between_triggers_seconds.value),
      templateRuleId: ruleControls.templateRuleId.value,
      notification: notification
    };
  }

  private cronValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const cronString: string = control?.value;
      if (cronString) {
        const cronResult = cron(cronString, {
          preset: 'default',
          override: {
            useAliases: true // override preset option
          }
        });
        return cronResult.isValid() ? null : {cronFormat: cronResult.getError()};
      } else {
        return {cronFormat: "Cron-formatted entry is required when rule is time-based (see https://en.wikipedia.org/wiki/Cron)"};
      }
    };
  }

  private ruleLanguageTermValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.errors ? {isInvalidTerm: true} : null;
    };
  }

  private ruleNameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const ruleName: string = control?.value?.trim();
      return ruleName && this.forbiddenRuleNames?.includes(ruleName) ? {forbiddenName: {value: ruleName}} : null;
    };
  }

  private prefillFormWithDefaultValues(): void {
    this.ruleForm.get(['time_between_triggers_seconds']).setValue('0');
    this.ruleForm.get(['notification', 'actionQualifier_primary']).setValue('OPENAPP');
    this.ruleForm.get(['notification', 'actionQualifier_secondary']).setValue('STOPDELIVERY');
    if (this.notificationLanguages?.includes('de')) {
      this.ruleForm.get(['notification', 'actions_primary_text_de']).setValue("DETAILS");
      this.ruleForm.get(['notification', 'actions_secondary_text_de']).setValue("NICHT ERNEUT ANZEIGEN");
    }
    if (this.notificationLanguages?.includes('en')) {
      this.ruleForm.get(['notification', 'actions_primary_text_en']).setValue("DETAILS");
      this.ruleForm.get(['notification', 'actions_secondary_text_en']).setValue("DON'T NOTIFY AGAIN");
    }
    if (this.notificationLanguages?.includes('fr')) {
      this.ruleForm.get(['notification', 'actions_primary_text_fr']).setValue("DÉTAILS");
      this.ruleForm.get(['notification', 'actions_secondary_text_fr']).setValue("NE PLUS NOTIFIER");
    }
  }

  private updateErrorBadgeOnLanguageTabs(): void {
    const ruleControls = this.ruleForm['controls'];
    const notificationControls = ruleControls.notification['controls'];
    for (let lang of this.notificationLanguages) {
      const isValid: boolean =
        notificationControls[`title_${lang}`].valid &&
        notificationControls[`message_${lang}`].valid &&
        notificationControls[`actions_primary_text_${lang}`].valid &&
        notificationControls[`actions_primary_param_${lang}`].valid &&
        notificationControls[`actions_secondary_text_${lang}`].valid &&
        notificationControls[`actions_secondary_param_${lang}`].valid;
      if (isValid) {
        this.errorBadgeLangTab.delete(lang);
      } else {
        this.errorBadgeLangTab.add(lang);
      }
    }
  }

  ruleLanguageTermChanged($event: RuleLanguageFormFieldComponent): void {
    $event.ngControl.control.updateValueAndValidity();
  }

  getRuleVersionNumber(ruleVersion: RuleVersion): string {
    const versionNumber = this.ruleVersions.length - this.ruleVersions.indexOf(ruleVersion);
    return `#${versionNumber}`;
  }

  getRuleVersionAge(ruleVersion: RuleVersion): string {
    const now: number = Math.floor(Date.now() / 1000);
    return RelativeDuration.asText(ruleVersion.timestamp, now);
  }

  openComparisonView(comparingRule: Rule): void {
    this.originalRule = JSON.parse(JSON.stringify(this.rule));
    this.comparingRule = comparingRule;
  }

  closeComparisonView(message: string): void {
    this.originalRule = undefined;
    this.comparingRule = undefined;
    this.snackBar.open(message, null, {
      duration: 2000,
      horizontalPosition: "center",
      verticalPosition: "bottom",
    })
  }

  getActionText(id: string): string {
    return this.actionSelection.find(it => it.id === id)?.text;
  }

  isEqualToRuleInForm(comparingRule: Rule): boolean {
    const rule: IRule = this.buildRule(this.rule.id, this.ruleForm);
    return RuleComparator.haveSameSettings(rule, comparingRule);
  }
}
